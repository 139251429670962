import React, { Component } from 'react';
import PropTypes from 'prop-types';

const cart = require("../img/cart.png");
const cartAdd = require("../img/cart-add.png");

class Product extends Component {
    handleClick = () => {
        const { id, addToCart, removeFromCart, isInCart } = this.props;

        if (isInCart) {
            removeFromCart(id);
        } else {
            addToCart(id);
        }

        // --------TOGGLES---------
        if (document.getElementById("product_btn").classList.toggle("displayNone"));

    }

    render() {
        const { name, price, currency, image, genre, rating, descriptionShort, isInCart } = this.props;

        return (

            <div className="product">

                <img className="product_image" src={image} alt="Product Image" />

                <section className="product_main">
                    <h2 className="product_title"> {name} </h2>

                    <div className="product_details">
                        <h5 className="product_detail"> {genre} </h5>
                        <h5 className="product_detail"> {rating} </h5>
                    </div>
                </section>

                <section className="product_contents">
                    <p className="product_description"> {descriptionShort} </p>
                </section>

                <section className="product_action">
                    <div className="product_price"> {currency}{price} </div>
                    <button
                        className={isInCart ? 'btn btn-danger' : 'btn btn-primary'} className="product_btn"
                        onClick={this.handleClick} id="product_btn"
                    >
                        <img id="product_cart" className="product_cart" src={cart} alt="Add to Cart"></img>
                        {isInCart ? 'Remove' : 'Add to Cart'}
                        <img id="product_cartAdd" className="product_cartAdd" src={cartAdd} alt="Added to Cart"></img>
                    </button>
                </section>

            </div>

        );
    }
}

Product.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number,
    currency: PropTypes.string,
    image: PropTypes.string,
    isInCart: PropTypes.bool.isRequired,
    addToCart: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired,
}

export default Product;
