import React from 'react';
import PropTypes from 'prop-types';
import Product from '../../containers/Product';

const ProductList = ({ products }) => {
    return (
        <section className="product_list__container">
            <ul className="product_list">
                {products.map(product => (
                    <li key={product.id} className="product_list__item">
                        <Product {...product} />
                    </li>
                ))}
            </ul>
        </section>
    );
}

ProductList.propTypes = {
    products: PropTypes.array,
}

export default ProductList;
