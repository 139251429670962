import React, { Component } from 'react';
import HeroList from './containers/HeroList';
import ProductList from './containers/ProductList';
import './App.css';

class App extends Component {
  render() {
    return (

      <div className="App">
        <div className="container">
          <HeroList />
          <ProductList />
        </div>
      </div>

    );
  }
}

export default App;