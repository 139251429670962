import React from 'react';
import PropTypes from 'prop-types';
import CartItem from './CartItem';

const checkout = require("../img/checkout.png");

const Cart = ({ items, total, currency, removeFromCart }) => {
    return (
        <div className="cart_container">

            <div className="dropCart">
                <button className="dropBtn"> <img src={checkout} className="checkout" /> </button>
                <div className="dropCart-content">

                    {items.length > 0 && (
                        <div className="cart__body">
                            {items.map(item => (
                                <CartItem key={item.id} {...item} onClick={() => removeFromCart(item.id)} />
                            ))}
                        </div>
                    )}
                    {items.length === 0 && (
                        <div className="cart_alert">Cart is empty</div>
                    )}
                    <div className="cart__total">Total: {currency}{total}</div>

                </div>
            </div>

        </div>
    );
}

Cart.propTypes = {
    items: PropTypes.array,
    total: PropTypes.number,
    currency: PropTypes.string,
    removeFromCart: PropTypes.func.isRequired
}

export default Cart;
