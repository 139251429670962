import React from 'react';
import PropTypes from 'prop-types';

const CartItem = ({ name, price, currency, onClick }) => {
    return (
        <div className="cart-item">
            <button className="btn__remove" onClick={onClick}>X</button>
            <span className="cart-item__name">{name}</span>
            <span className="cart-item__price">{currency}{price}</span>
        </div>
    );
}

CartItem.propTypes = {
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default CartItem;