import React from 'react';
import PropTypes from 'prop-types';
import Hero from '../../containers/Hero';

const HeroList = ({ products }) => {
    return (

        <div className="hero_list_container">
            <ul className="hero_list">
                {products.map(product => (
                    <li key={product.id} className="hero_list__item">
                        <Hero {...product} />
                    </li>
                ))}
            </ul>
        </div>

    );
}

HeroList.propTypes = {
    products: PropTypes.array,
}

export default HeroList;
