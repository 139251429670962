const productData = [
    {
        id: 1,
        name: 'Cyberpunk 2077',
        image: 'images/cyberpunk-full.jpg',
        price: 59.99,
        currency: '$',
        developer: "CD PROJEKT RED",
        genre: "Sci-Fi Action RPG",
        rating: "Mature",
        releaseDate: "4-16-20",
        description: "Cyberpunk 2077 is an open-world, action-adventure story set in Night City, a megalopolis obsessed with power, glamour and body modification. You play as V, a mercenary outlaw going after a one-of-a-kind implant that is the key to immortality. You can customize your character’s cyberware, skillset and playstyle, and explore a vast city where the choices you make shape the story and the world around you.",
        descriptionShort: "Set in Night City, a megalopolis obsessed with power, glamour and body modification. You play as V, a mercenary outlaw going after a one-of-a-kind implant that is the key to immortality. You can customize your character’s cyberware, skillset and playstyle, and explore a vast city where the choices you make shape the story and world around you.",
    },
    {
        id: 2,
        name: 'The Last of Us Part II',
        image: 'images/tloudana.jpg',
        price: 59.99,
        currency: '$',
        developer: "Naughty Dog",
        genre: "Action Adventure",
        rating: "Mature",
        releaseDate: "5-29-20",
        description: "Five years after their dangerous journey across the post-pandemic United States, Ellie and Joel have settled down in Jackson, Wyoming. Living amongst a thriving community of survivors has allowed them peace and stability, despite the constant threat of the infected and other, more desperate survivors. When a violent event disrupts that peace, Ellie embarks on a relentless journey to carry out justice and find closure. As she hunts those responsible one by one, she is confronted with the devastating physical and emotional repercussions of her actions.",
        descriptionShort: "Five years after their dangerous journey across the post-pandemic United States, Ellie embraks on a relentless journey to carry out justice and find closure.  As she hunts down those responsible one by one, she's confronted with the devastation repercussions of her actions.",
    },
    {
        id: 3,
        name: 'Overwatch 2',
        image: 'images/ow2.jpg',
        price: 59.99,
        currency: '$',
        developer: "Blizzard Entertainment",
        genre: "First-Person Shooter",
        rating: "Teen",
        releaseDate: "TBA",
        description: "Reunite and stand together in a new age of heroes. Overwatch® 2 builds on an award-winning foundation of epic competitive play, and challenges the world’s heroes to team up, power up, and take on an overwhelming outbreak of threats around the globe.",
        descriptionShort: "Reunite and stand together in a new age of heroes. Overwatch® 2 builds on an award-winning foundation of epic competitive play, and challenges the world’s heroes to team up, power up, and take on an overwhelming outbreak of threats around the globe.",
    },
    {
        id: 4,
        name: 'Call of Duty: Modern Warfare',
        image: 'images/codmw.png',
        price: 59.99,
        currency: '$',
        developer: "Infinity Ward",
        genre: "First-Person Shooter",
        rating: "Mature",
        currency: '$',
        releaseDate: "10-25-19",
        description: "Prepare to go dark, Modern Warfare is back! The stakes have never been higher as players take on the role of lethal Tier One operators in a heart-racing saga that will affect the global balance of power. Call of Duty: Modern Warfare engulfs fans in an incredibly raw, gritty, provocative narrative that brings unrivaled intensity and shines a light on the changing nature of modern war. Developed by the studio that started it all, Infinity Ward delivers an epic reimagining of the iconic Modern Warfare series from the ground up.",
        descriptionShort: "Players take on the role of lethal Tier One operators in a heart-racing saga that will affect the global balance of power. Call of Duty: Modern Warfare engulfs fans in an incredibly raw, gritty, provocative narrative that shines a light on the changing nature of modern war.",
    },
    {
        id: 5,
        name: 'Final Fantasy VII Remake',
        image: 'images/ffviiremake.jpg',
        price: 59.99,
        currency: '$',
        developer: "Square Enix",
        genre: "Action Adventure RPG",
        rating: "Teen",
        releaseDate: "3-3-20",
        description: "The world has fallen under the control of the Shinra Electric Power Company, a shadowy corporation controlling the planet’s very life force as mako energy. In the sprawling city of Midgar, an anti-Shinra organization calling themselves Avalanche have stepped up their resistance. Cloud Strife, a former member of Shinra’s elite SOLDIER unit now turned mercenary, lends his aid to the group, unaware of the epic consequences that await him.",
        descriptionShort: "In the sprawling city of Midgar, an anti-Shinra organization calling themselves Avalanche have stepped up their resistance. Cloud Strife, a former member of Shinra’s elite SOLDIER unit now turned mercenary, lends his aid to the group, unaware of the epic consequences that await him.",
    },
    {
        id: 6,
        name: 'Bloodborne',
        image: 'images/bloodborne-2.jpg',
        price: 19.99,
        currency: '$',
        developer: "FromSoftware",
        genre: "Action Adventure RPG",
        rating: "Mature",
        releaseDate: "3-24-15",
        description: "Introducing Bloodborne, the latest Action RPG from renowned Japanese developer FromSoftware, makers of the hit Dark Souls series. Face your fears as you search for answers in the ancient city of Yharnam, now cursed with a strange endemic illness spreading through the streets like wildfire. Danger, death and madness lurk around every corner of this dark and horrific world, and you must discover its darkest secrets in order to survive.",
        descriptionShort: "Face your fears as you search for answers in the ancient city of Yharnam, now cursed with a strange endemic illness spreading through the streets like wildfire. Danger, death and madness lurk around every corner of this dark and horrific world, and you must discover its darkest secrets in order to survive.",
    },
];

export default productData;
