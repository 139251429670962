import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cart from '../../containers/Cart';

const cart = require("../img/cart.png");
const cartAdd = require("../img/cart-add.png");
// ------RELEASE COUNTDOWN------
// RELEASE DATE
const releaseTime = new Date("Mar 16, 2020").getTime();

// UPDATE EVERY SECOND
var x = setInterval(function () {

    // TODAY
    var now = new Date().getTime();

    // TIME BEFORE RELEASE
    var distance = releaseTime - now;

    // TIME CALCULATIONS
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Output the result in an element with id="demo"
    document.getElementById("countdown").innerHTML = days + "d " + hours + "h "
        + minutes + "m " + seconds + "s ";

    // If the count down is over, write some text 
    if (distance < 0) {
        clearInterval(x);
        document.getElementById("countdown").innerHTML = "";
    }
}, 1000);

class Hero extends Component {
    handleClick = () => {
        const { id, addToCart, removeFromCart, isInCart } = this.props;

        if (isInCart) {
            removeFromCart(id);
        } else {
            addToCart(id);
        }

        // --------TOGGLES---------
        if (document.getElementById("hero_button").classList.toggle("heroToggle"));
        if (document.getElementById("hero_cart").classList.toggle("displayNone"));


        if (document.getElementById("hero_cartAdd").className == "hero_cartAdd")
            document.getElementById("hero_cartAdd").className = "hero_cartAdded";
        else
            document.getElementById("hero_cartAdd").className = "hero_cartAdd";
    }

    render() {
        const { name, price, currency, developer, genre, rating, releaseDate, description, isInCart } = this.props;

        return (

            <div className="hero">

                <Cart />

                <section className="hero_main">
                    <h1 className="hero_title"> {name} </h1>

                    <div className="hero_details">
                        <h5 className="hero_detail"> {developer} </h5>
                        <h5 className="hero_detail"> {genre} </h5>
                        <h5 className="hero_detail"> {rating} </h5>
                        <h5 className="hero_detail"> {releaseDate} </h5>
                        <h5 id="countdown" className="hero_detail"></h5>
                    </div>
                </section>

                <section className="hero_contents">
                    <p className="hero_description"> {description} </p>
                </section>

                <section className="hero_action">
                    <div className="hero_price"> {currency}{price} </div>
                    <button
                        className={isInCart ? 'btn btn-danger' : 'btn btn-primary'} className="hero_button"
                        onClick={this.handleClick} id="hero_button"
                    >
                        <img id="hero_cart" className="hero_cart" src={cart} alt="Add to Cart"></img>
                        {isInCart ? 'Remove' : 'Add to Cart'}
                        <img id="hero_cartAdd" className="hero_cartAdd" src={cartAdd} alt="Added to Cart"></img>
                    </button>
                </section>

            </div>

        );
    }
}


Hero.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number,
    currency: PropTypes.string,
    image: PropTypes.string,
    isInCart: PropTypes.bool.isRequired,
    addToCart: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired,
}

export default Hero;
